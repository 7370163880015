import React, { forwardRef, useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ContactContent from "../components/PagesComponents/ContactContent";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
const Container = styled.div``;
const Alert =forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
const Contact = () => {
  const [waitingMail,setWaitingMail] = useState(false);
  const [open, setOpen] = useState(false);
  const [mailMessage, setMailMessage] = useState('');
  useEffect(()=>{
    window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  },[])
  const sendMail = async (values) => {
    setWaitingMail(true)
    let returnValue = true;
    let url = `${process.env.REACT_APP_API}/mail`;
    await fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        mail: values.email,
        name: values.name,
        message: values.message,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setMailMessage(res.message)
        setWaitingMail(false)
        setOpen(true)
        returnValue = res.status        
      })
      .catch((err) => {
        setMailMessage(err.message)
        setWaitingMail(false)
        setOpen(true)
        returnValue = false
      });
      return returnValue
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <Container>
      <Header />
      <ContactContent sendMail={sendMail} waitingMail={waitingMail} />
      <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={mailMessage === 'Expo Foods Midlands Contact Mail Success' ? "success": "error"} sx={{ width: '100%' }}>
            {mailMessage}
        </Alert>
      </Snackbar>
    </Stack>
      <Footer />
    </Container>
  );
};

export default Contact;
