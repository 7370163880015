import { useState,forwardRef, useEffect } from "react";
import styled from "styled-components";
import {colors} from "../../res/values/values"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../redux/actions/userActions";
import useForm from "../../utilities/hooks/useForm";
import {validateMailInfo} from '../../utilities/helpers';
import { useNavigate } from "react-router-dom";

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import MButton from '@mui/material/Button';
import { CircularProgress } from "@mui/material";

//#region STYLES

const Container = styled.div`
  min-height: 40vw; 
  display: flex;
  flex-direction: column;
 
  padding-top: 20px;
`;
const Wrapper = styled.div`
  padding: 20px;
  background-color: white;
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: 300;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 10px 0;
  padding: 10px;  
  border: 1px;
  border-color: lightgray;
  border-style: solid;
  &:focus  {
    outline: none !important;
    border-color: ${colors.primaryColor};
  }
`;
const Button = styled.button`
  border: none;
  padding: 15px 20px;
  background-color: ${colors.primaryColor};
  color: white;
  margin-bottom: 10px;
  cursor: pointer;
  font-size:18;
`;

//#endregion

const Alert =forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

function ForgotPassword(props) {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const [mailMessage, setMailMessage] = useState('');
  const [waiting, setWaiting] = useState(false);
  let counter = 0;
  useEffect(()=> {
    if (mailMessage === 'Expo Foods Midlands Reset Password Mail Success') {
      setOpen(true);
      setTimeout(function() {navigate("/")}, 2000);
    }
  },[mailMessage])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const forgotPasswordMail = async (values) => {
    let user={
      "mail":values.email,
    }
    setWaiting(true);
    let url = `${process.env.REACT_APP_API}/auth/forgotPasswordMail`;
    await fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === false) {
          counter++;
          if (counter === 3) {
            setMailMessage(res.message)
            setWaiting(false)
            setOpen(true)
          }else {
            console.log(counter);
            setTimeout(function() {forgotPasswordMail(values)}, 5000);
          }
        }else{
            setMailMessage(res.message)
            setOpen(true)
        }
        
        
      })
      . catch((err) => {
        setMailMessage(err.message)
      });
   
  };

  const { handleChange, handleSubmit, values, errors } = useForm(
    forgotPasswordMail,
    validateMailInfo
  );
  return (
    <Container>
      <Wrapper>
        <Title>Forgot Password</Title>
        <Form onSubmit={handleSubmit}>
          <Input name="email" onChange={handleChange} type="text" placeholder="email" value={values.email}/>
          {errors.email && <p style={{color:'red'}}>{errors.email}</p>}
          <Button type="submit" disabled={waiting}>
          { waiting ? (<CircularProgress size={16} thickness={4} style={{color:'white'}} />) : "Reset Password" } 
          </Button>
        </Form>
      </Wrapper>
      <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={mailMessage === 'Expo Foods Midlands Reset Password Mail Success' ? "success": "error"} sx={{ width: '100%' }}>
            {mailMessage}
        </Alert>
      </Snackbar>
    </Stack>
    </Container>
  );
}

export default ForgotPassword;
